.sidebar {
    width: 250px;
    height: 100vh;
    background-color: #fff;
    box-shadow: 2px 0 5px rgba(0,0,0,0.1);
    position: fixed;
    top: 0;
    left: 0;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    z-index: 999;
}

.menu {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex: 1;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    font-size: 18px;
    color: #333;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.menu-item ion-icon {
    font-size: 24px;
    margin-right: 15px;
}

.menu-item:hover {
    background-color: #f0f0f0;
    color: #000;
}

.menu-item-active{
    background-color: #f0f0f0;
    color: #000;
    border-left: solid 5px #000;
}

/* Adiciona uma barra de rolagem personalizada */
.sidebar::-webkit-scrollbar {
    width: 8px;
}

.sidebar::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}