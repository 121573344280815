/*Galery*/

.parceiro-gallery-modal{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.7);
    z-index:-1;
    display:flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.5s ease-in-out;
  }
  .parceiro-gallery-modal.active{
    opacity:1;
    z-index:100;
  }
  .parceiro-gallery-modal .parceiro-gallery-btn{
    position: absolute;
    top:20px;
    right:20px;
    color:#fff;
    background-color: transparent;
    border:0;
    font-size: 25px;
    cursor: pointer;
  }
  .parceiro-gallery-main{
    margin-top: 0px;
  }
  .parceiro-gallery-grid{
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:10px;
  }
  
  .parceiro-gallery-grid img{
    width: calc(100% - 0px);
    height: 180px;
    cursor: pointer;
    transition: opacity 0.5s ease-in-out;
  }
  .parceiro-gallery-grid img:hover{
    opacity:0.8;
  }
  
  @media (max-width:980px) {
    .parceiro-gallery-grid{
      grid-template-columns: repeat(2, 1fr);
    }
  }