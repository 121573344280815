.dot-parent{
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    border-radius: 16px;
    font-size: 14px;
}

.dot{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.dot-parent-red{
    border: solid 1px red;
    color: red;
    background-color: rgb(255, 218, 218);
}

.dot-parent-orange{
    border: solid 1px orange;
    color: orange;
    background-color: rgb(255, 238, 207);
}

.dot-parent-green{
    border: solid 1px rgb(7, 202, 56);
    color: rgb(7, 202, 56);
    background-color: rgb(238, 248, 238);
}

.dot-parent-blue{
    border: solid 1px rgb(0, 180, 252);
    color: rgb(0, 180, 252);
    background-color: rgb(213, 241, 252);
}

.dot-parent-brown{
    border: solid 1px rgb(63, 43, 43);
    color: rgb(63, 43, 43);
    background-color: rgb(221, 221, 221);
}

.dot-parent-redlight{
    border: solid 1px rgb(233, 19, 119);
    color: rgb(233, 19, 119);
    background-color: rgb(253, 220, 236);
}

.dot-parent-purple{
    border: solid 1px purple;
    color: purple;
    background-color: rgb(253, 234, 253);
}

.dot-red{
    background-color: red;
}

.dot-orange{
    background-color: orange;
}

.dot-green{
    background-color: rgb(7, 202, 56);
}

.dot-blue{
    background-color: rgb(0, 180, 252);
}

.dot-brown{
    background-color: rgb(63, 43, 43);
}

.dot-redlight{
    background-color: rgb(233, 19, 119);
}

.dot-purple{
    background-color: purple;
}