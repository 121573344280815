.container-ratting {
    border-radius: 15px;
    gap: 40px;
  }
  .chart {
    /* width: 500px; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
  }
  
  .chart .rate-box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 30px;
    padding: 20px 0;
    padding: 10px;
  }
  .chart .rate-box > * {
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #444;
  }
  .rate-box .value {
    display: flex;
    align-items: center;
  }
  .rate-box .value:hover {
    color: #66bb6a;
  }
  .chart .value {
    font-size: 30px;
    cursor: pointer;
  }
  .rate-box .progress-bar {
    border-width: 1px;
    position: relative;
    background-color: #cfd8dc91;
  
    height: 10px;
    border-radius: 100px;
    width: 350px;
  }
  .rate-box .progress-bar .progress {
    background-color: #66bb6a;
    height: 100%;
    border-radius: 100px;
    transition: 300ms ease-in-out;
  }
  
  .global {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
  }
  .one .fas {
    color: #cfd8dc;
  }
  
  .two {
    background: linear-gradient(to right, #66bb6a 0%, transparent 0%);
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    transition: 0.5s ease-in-out all;
  }
  
  .global > span {
    font-size: 90px;
    font-weight: 500;
  }
  
  .rating-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 10%;
    color: orange;
  }
  .rating-icons span {
    position: absolute;
    display: flex;
    font-size: 30px;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 5px;
  }
  
  .total-reviews {
    font-size: 25px !important;
  }
  