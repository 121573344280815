.pagination-container {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-overflow {
    display: flex;
    overflow-x: auto;
}

.table-container {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
}

thead tr {
    background-color: #f2f2f2;
}

th, td {
    padding: 12px 15px;
}

th {
    background-color: #333;
    color: #fff;
}

tr:nth-of-type(even) {
    background-color: #f9f9f9;
}

tr:hover {
    background-color: #f1f1f1;
}

td {
    border-bottom: 1px solid #ddd;
}

/* Estilos para a responsividade */
@media (max-width: 768px) {
    table, thead, tbody, th, td, tr {
        display: block;
    }

    thead tr {
        display: none;
    }

    tr {
        margin-bottom: 15px;
        border: 1px solid #ddd;
        border-radius: 8px;
        padding: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    td {
        text-align: left;
        padding-left: 0;
        position: relative;
    }

    td::before {
        content: attr(data-label);
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        color: #333;
        text-align: left;
    }

    td:last-child {
        margin-top: 10px;
        text-align: center;
    }
}

/* Adiciona uma barra de rolagem personalizada */
.table-container::-webkit-scrollbar {
    width: 4px;
}

.table-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background-color: #aaa;
}