.text-left{
    text-align: left;
}

.item-product{
    display: inline-block;
    user-select: none;
    color: #222;
    font-size: 13px;
    font-weight: bold;
    width: 90px;
    padding: 4px;
    border-radius: 8px;
    border: solid 1px #f5f5f5;
    background-color: #fff;
}

.item-product-desktop{
    display: inline-block;
    user-select: none;
    color: #222;
    font-size: 14px;
    font-weight: bold;
    width: 90px;
}

.item-product-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px; 
}

.item-product-icon-desktop{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px; 
    height: 80px;
}

.item-product > .item-product-icon > img { 
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.item-product-desktop > .item-product-icon-desktop > img { 
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}
  
.item-product, .item-product-desktop {
    margin-right: 5px;
}
  
.item-product-b{}

.item-product-price{
    font-weight: bold;
    color: green;
}
  
@media screen and (min-width: 1200px) {
    .wrapper {
      margin-left: -20px;
    }
}