.input-box.active-grey .input-1 {
    border: 1px solid #dadce0;
  }
  .input-box.active-grey .input-label {
    color: #80868b;
    top: -8px;
    background: #fff;
    font-size: 11px;
    transition: 250ms;
  }
  .input-box.active-grey .input-label svg {
    position: relative;
    width: 11px;
    height: 11px;
    top: 2px;
    transition: 250ms;
  }

  .input-box {
    position: relative;
    margin: 10px 0;
  }
  .input-box .input-label {
    position: absolute;
    color: #80868b;
    font-size: 16px;
    font-weight: 400;
    max-width: calc(100% - (2 * 8px));
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    left: 8px;
    top: 13px;
    padding: 0 8px;
    transition: 250ms;
    user-select: none;
    pointer-events: none;
  }
  .input-box .input-label svg {
    position: relative;
    width: 15px;
    height: 15px;
    top: 2px;
    transition: 250ms;
  }
  .input-box .input-1 {
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    color: #202124;
    border: 1px solid #dadce0;
    padding: 13px 15px;
    transition: 250ms;
  }
  .input-box .input-1:focus {
    outline: none;
    border: 2px solid black;
    transition: 250ms;
  }

  .input-box.error .input-label {
    color: #f44336;
    top: -8px;
    background: #fff;
    font-size: 11px;
    transition: 250ms;
  }
  .input-box.error .input-1 {
    border: 2px solid #f44336;
  }

  .input-box.focus .input-label,
  .input-box.active .input-label {
    color: black;
    top: -8px;
    background: #fff;
    font-size: 11px;
    transition: 250ms;
  }
  .input-box.focus .input-label svg,
  .input-box.active .input-label svg {
    position: relative;
    width: 11px;
    height: 11px;
    top: 2px;
    transition: 250ms;
  }

  .input-box.active .input-1 {
    border: 2px solid black;
  }
  .pull-right {
    float: right;
  }
  .clear {
    clear: both;
  }