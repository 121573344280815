.fade-in {
    animation: fadeIn ease 0.5s;
}

@keyframes fadeIn {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}