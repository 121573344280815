.mockup {
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 10;
    overflow: hidden;
  }
  
  nav {
    position: absolute;
    bottom: 15px;
    width: 95%;
  }

  nav div {
    width: 40px;
    height: 40px;
    border-radius: 10px;
    background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
    cursor: pointer;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    margin: 0px auto;
  }
  
  .bottom-sheet-wrapper .backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 1;
  }
  .bottom-sheet-wrapper .bottom-sheet {
    background: #fff;
    width: 97%;
    min-height: 200px;
    position: absolute;
    z-index: 2;
    border-radius: 15px;
    transition: all 0.2s ease-in-out;
    left: 50%;
    transform: translateX(-50%);
    bottom: -110%;
  }
  .bottom-sheet-wrapper.show-modal .backdrop {
    opacity: 1;
    pointer-events: all;
  }
  .bottom-sheet-wrapper.show-modal .bottom-sheet {
    bottom: 5px;
  }
  
  .close {
    position: relative;
    width: 20px;
    height: 20px;
    float: right;
    margin: 10px;
    cursor: pointer;
  }
  .close span {
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: red;
    transform: rotate(45deg);
  }
  .close span::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    background: red;
    transform: rotate(90deg);
  }

/*=========================*/

.item-agenda{
  display: inline-block;
  user-select: none;
  color: #000;
  font-size: 13px;
  font-weight: bold;
  width: 45px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 4px;
  border: 1px #ddd solid;
}

.item-hour{
  display: inline-block;
  user-select: none;
  color: #000;
  font-size: 13px;
  font-weight: bold;
  width: 55px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 4px;
  border: 1px #ddd solid;
}

.item-agenda-icon{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px; 
  height: 72px;
  background-color: #fff;
  border-radius: 50%;
  border-radius: solid #ddd 1px;
}

.item-agenda > .item-agenda-icon > img { 
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.item-agenda {
  margin-right: 5px;
}

.item-hour {
  margin-right: 5px;
}

.item-agenda-b{
  word-wrap: break-word;
  white-space: normal;
}

@media screen and (min-width: 1200px) {
  .wrapper {
    margin-left: -20px;
  }
}

/*======================*/

.period-button{
  background: #f5f5f5;
  color: black;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
}

.hora-button{
  background: #f5f5f5;
  color: black;
  font-weight: bold;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 4px;
  text-align: center;
}

.li-colaborador{
  padding: 4px;
  display: flex;
  align-items: center;
  transition: .2s;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.03);
  margin-top: 1px;
}

.li-colaborador:hover{
  padding: 4px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  transition: .2s;
}

.li-colaborador:active{
  padding: 4px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  transition: .2s;
}
