.swipper-refresh-igend {
  position: relative;
  overflow-y: auto;
  height: 100vh;
}

.indicator-igend {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: gray;
  font-size: 18pt;
  height: 35px;
  width: 35px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}