.body-terms {
    line-height: 1.6;
    color: #333;
}
.section-terms ul {
    margin-left: 20px;
}
.section-terms {
    margin-bottom: 20px;
}

.body-terms h1 {
    font-size: 16pt;
}

.body-terms h2 {
    font-size: 14pt;
}

.body-terms h3 {
    font-size: 11pt;
}