.header {
    height: 25vh;
    background-repeat: no-repeat;
    background-size: contain;
    background-attachment: fixed;
    box-shadow: 0 40px 80px #000 inset, 0 -40px 80px #000 inset;
}

.header-actions{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
}

.header-button{
    background-color: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.6rem;
}

.header-bottom-box{
    position: absolute;
    bottom: calc(0px - 1px);
    width: 100%;
    height: 40px;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    background-color: #f5f5f5;
}

.header-body{
    position: absolute;
    bottom: calc(0px - 0px);
    margin-left: 16px;
    margin-right: 16px;
}

.service-item{
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 8px;
    justify-content: space-between;
    padding: 6px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.05);
    margin-bottom: 5px;
}

.banner-assinatura{
    background: rgb(196,175,255);
    background: linear-gradient(90deg, rgba(196,175,255,1) 0%, rgba(152,181,255,1) 100%);
}

.location-text{
    font-size: 0.775rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
}

.button-group-default-outline{
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-weight: bold;
    cursor: pointer;
}

.button-group-default-outline:nth-child(1){
    border-left: solid 1px #000;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.button-group-default-outline:nth-child(2){
    border-left: solid 1px #000;
}

.button-group-default-outline:nth-child(3){
    border-left: solid 1px #000;
    border-right: solid 1px #000;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.button-group-default-outline-active{
    background-color: #000;
    color: white;
}

.pricing-row{
    display: flex;
    align-items: center;
    justify-content: center;
}
