.item {
    display: inline-block;
    margin-right: 20px;
    user-select: none;
    width: 70%;
    height: 130px;
    color: #222;
    border-radius: 8px;
}

.item:last-child {
    margin-right: 15px;
}
  
@media screen and (min-width: 500px) {
    .item {
      width: 33%;
    }
}

@media screen and (min-width: 800px) {
    .item {
      width: 25%;
    }
}

@media screen and (min-width: 1200px) {
    .wrapper {
      margin-left: -20px;
    }
  
    .item {
      width: 40%;
    }
}